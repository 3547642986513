/* ==========
ROOT STUFF
= */

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  line-height: 1.4;
}

html{
  --backgroundColor: #232b2b;
  --wrapperColor: #232b2b;
  --fontSize: 16px;
  --fontColor: 245,245,245;
  --gradientColor1: #5FC3E4;
  --gradientColor2: #E55D87;
  font-size: var(--fontSize);
}

body{
  background: var(--backgroundColor);
  padding: 16px 8px;
  color: rgba(var(--fontColor),1);
}

.gradientBorder{
  max-width: 726px;
  margin: 0 auto;
  padding: 4px;
  border-radius: 20px;
  background: conic-gradient(var(--gradientColor1), var(--gradientColor2), var(--gradientColor1),var(--gradientColor2), var(--gradientColor1));
  box-shadow: 0px 0px 15px 15px rgba(0,0,0,0.24);
  backdrop-filter: blur(10px);
}

.wrapper{

  width: 100%;

  background: none;

  border-radius: 20px;

}

/* ==========
HEADER STUFF
========== */

header{
  display: flex;
  padding: 32px;
  background: var(--wrapperColor);
  width: 100%;
  border-radius: 20px 20px 0 0;
  position: relative;
}


.burgerContainer{
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  text-align: center;
  width: 45px;
  height: 45px;
  transition: all .6s ease;
}
.burgerContainer nav{
  padding: 32px;
  background: var(--backgroundColor);
  border-radius: 8px;
  transform:scale(25%);
  cursor: pointer;
  transition: all .6s ease;
}

.burgerContainer ul{
  list-style: none;
}

.burgerContainer a{
  color: rgba(var(--fontColor),0);
  font-weight: bold;
  text-decoration: none;
  pointer-events: none;
  transition: all .6s ease;
}

.burgerContainer li:after{
  content: '';
  display: block;
  background: rgba(var(--fontColor),.7);
  width: 100%;
  height: 8px;
  border-radius: 2px;
  margin: 0;
  transition: all .6s ease;
}

.burgerContainer[class~="expanded"]{
  width: 100%;
  height: 100%;
  backdrop-filter:blur(16px);
  top: 0;
  right: 0;
  cursor: pointer;
  border-radius: 50px;
}

.burgerContainer[class~="expanded"] nav{
  transform: scale(100%);
  cursor: default
}

.burgerContainer[class~="expanded"] a{
  color: rgba(var(--fontColor),.7);
  pointer-events: auto
}

.burgerContainer[class~="expanded"] a:hover{
  color: rgba(var(--fontColor),1);
}

.burgerContainer[class~="expanded"] .active{
  color: var(--gradientColor2);
  pointer-events: none
}

.burgerContainer[class~="expanded"] li:after{
  height: 4px;
  margin: 4px 0;
}

@keyframes appear{
  from{
    opacity: 0;
    
  }to{
    opacity: 1;
    
  }
}

#portrait{
  opacity: 0;
  border-radius: 50%;
  width: 175px;
  height: 175px;
  border: 6px solid rgba(var(--fontColor),.7);
  animation: appear 1s ease forwards;
}

h1{
  font-size: 2.1rem;
  line-height: 0;
  padding-bottom:1em;
}

.info{
  padding: 16px 0 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info p{
  font-size: 1rem;
}

.socialLinks{
  display: flex;
  justify-content: space-around;
  padding-top: 24px;
}

.socialLinks a{
  display: block;
}

.socialLinks a svg{
  aspect-ratio: 1;
  height: 32px;
  opacity: .7;
  fill: rgba(var(--fontColor),1);
  transition: opacity .6s ease, bottom .6s ease;
  position: relative;
}

.socialLinks a:hover svg{
  opacity: 1;
  bottom: 2px;
}

.skillIcons{
  padding: 8px 0;
  display: flex;
  justify-content: space-around;
}

.skillIcons svg{
  height: 40px;
  fill: var(--wrapperColor)
}

/*=========
HOME
=========== */

.home{
  border-radius: 0 0 16px 16px;
  overflow: hidden;
}

.home .copy{
  background: var(--backgroundColor);
  padding: 32px;
  
}

.copy a{
  color: rgba(var(--fontColor),1);
  text-decoration: none;
  padding-bottom: .8px;
  border-bottom: 1px solid var(--gradientColor1);
  transition: border-color .6s ease;
}
.copy a:hover{
  border-color: var(--gradientColor2);
}

.home h2{
  margin-bottom: 24px;
}

.home .quoteContainer{
  padding: 32px;
}

.home figure{
  width: 100%;
  padding: 24px 16px;
  background: var(--backgroundColor);
  border-radius: 16px;
  position: relative;
}

.home figure blockquote{
  padding-left: 16px;
  border-left: 4px solid var(--gradientColor2);
}

.home figure .captionContainer{
  display: flex;
  justify-content: flex-end;
}

.home figure figcaption span{
  font-weight: bold;
  color: var(--gradientColor2);
}


/* ==========
RESUME STUFF
========== */

main{

}
.wrapper{
  backdrop-filter: blur(25px);
}
#meat{
  display: flex;
  flex-direction: column;
  border-radius: 0 0 16px 16px;
  overflow: hidden;
}

.downloadResume{
  padding: 16px 8px;
  text-align: center;
  color: var(--backgroundColor);
  border-top: 64px solid var(--backgroundColor);
}
.downloadResume a{
  display: inline-block;
  padding: 8px 16px;
  background: var(--backgroundColor);
  color: var(--gradientColor1);
  font-weight: bold;
  border-radius: 8px;
  margin-top: 16px;
  cursor: pointer;
  border: 2px solid var(--backgroundColor);
  transition: background .6s ease, color .6s ease;
  text-decoration: none;
}

.downloadResume a:hover{
  background: var(--gradientColor1);
  color: var(--backgroundColor);
}

#meat h2{
  font-size: 1.4rem
}
#meat h3, #meat h4, #meat p, #meat li{
  font-size: 1.2rem;
}

#meat p, #meat li{
  font-size: 1rem;
}
#meat h4, #meat h3{
  font-weight: normal;
}

/* WORK EXPERIENCE */

#meat #workExp{
  background: var(--wrapperColor);
  padding: 32px;
  border-radius: 0
}

.jobInfo{
  border-bottom: 2px solid rgba(var(--fontColor),1);
  position: relative;
  /* padding: 32px 32px 32px 0px; */
}

#workExp section:first-of-type .jobInfo{
  border-top: 2px solid rgba(var(--fontColor),1);
  margin-top: 32px;
}

.jobInfo:after, .jobInfo:before{
  content:'';
  display: block;
  width: 16px;
  height: 4px;
  background: var(--gradientColor1);
  position: absolute;
  top:70px;
  right: 0;
  transition: transform .6s ease, background .6s ease;
}

.expanded:before{
  background: var(--gradientColor2);
  transform: rotate(90deg);
}
.expanded:after{
  background: var(--gradientColor2);
}

.jobHeader{
  padding: 32px 32px 32px 0;
  border-bottom: 2px solid rgba(var(--fontColor),0);
  transition: border-color .6s ease;
  cursor: pointer;
}

.jobHeader p{
  margin-bottom: 0;
}

.expanded .jobHeader{
  border-bottom: 2px solid rgba(var(--fontColor),1);
}

.jobInfo .hidden{
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  padding: 0;
  transition: max-height .6s ease, opacity .6s ease, padding .6s ease-out;
}

.expanded .hidden{
  max-height: 100vh;
  overflow: auto;
  opacity: 1;
  padding: 32px 0;
}

.hidden{
  list-style: none;
}

.hidden li{
  margin-bottom: 1em;
}

/* .jobInfo .hidden li{
  font-size: 0;
  line-height: 0;
  margin:0; 
  opacity: 0;
}

.jobInfo .hidden li:last-child, #workExp .hidden li:first-child{
padding: 0;
}

.expanded ul{
  list-style: none;
}
.expanded ul li{
  transition: font-size .25s ease, line-height .25s ease, margin .25s ease, opacity .25s ease .25s, padding .25s ease;
  margin-bottom: 1em;
}

.expanded ul li:first-child{
  padding-top: 32px;
}

.expanded ul li:last-child{
  padding-bottom: 32px;
  border-bottom: 2px solid rgba(var(--fontColor),1); 
  margin: 0;
} */



#references{
  background: var(--wrapperColor);
  padding: 32px;
  border-radius: 0 0 20px 20px; 
  display: flex;
  justify-content: space-around;
}

#references section{
  text-align: center;
  width: 200px;
}

#references section img{
  width: 100px;
  border: 6px solid rgba(var(--fontColor),.7);
  border-radius: 50%;
  filter: grayscale(100%);
}

#quote{
padding-top: 32px;
}

#quote h2{
  text-align: center;
  color: var(--wrapperColor)
}

#quote div{
  padding: 32px 0 0;
  display: flex;
  flex-direction: column-reverse;
  
}

/* figcaption{
  text-align: center;
  background: linear-gradient(to top, var(--wrapperColor) 60%, rgba(0,0,0,0) 60%)
}

figcaption img, #references img{
  aspect-ratio: 1;
  border-radius: 50%;
  border: 6px solid rgba(var(--fontColor),0.7);
  filter: grayscale(100%);
  box-shadow: 0px 0px 0px rgba(255,255,255,0);
  transition: border-color .6s ease,box-shadow .6s ease;
}
figcaption img:hover, #references img:hover{
  border-color: 6px solid rgba(var(--fontColor),1);
  box-shadow: 0 0 15px 15px rgba(255,255,255,0.1);
  
}

figcaption img {
  width: 150px;
  
} */



/* blockquote{
  background: var(--wrapperColor);
  padding: 32px;
}
blockquote p{
  max-width: 450px;
  margin: 0 auto 1em;
} */


/*=====================
Projects
=====================*/

.projects{
  background: var(--backgroundColor);
  padding: 32px;
  border-radius: 0 0 20px 20px;
}

.projects h2{
  margin: 0 0 32px;
}

.projectContainer{
  margin-bottom: 32px;
}

.project{
  display: flex;
  align-items: center;
  background: var(--backgroundColor);
  border-radius: 20px; 
  overflow: hidden;
  width: 100%;
}

.projectInfo{
  width: 62%;
  padding: 16px;
}

.projectInfo h3, p{
  margin-bottom: 16px;
}

.projectInfo .buttons{
  display: flex;
  gap: 16px;
  font-weight: bold;
  color: rgba(var(--fontColor),1)
}

.projectInfo .buttons a{
  color: rgba(var(--fontColor),1);
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: 2px solid var(--gradientColor1);
  transition: border-color .6s ease;
}

.projectInfo .buttons a:hover{
  border-color: var(--gradientColor2);
}

.thumbnailContainer{
  aspect-ratio: 1/1;
  width: 38%;
  background: grey;
  overflow:hidden;
  border-left: 2px solid var(--gradientColor1);
}

.thumbnailContainer img{
  position: relative;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100%;
  transition: all .6s ease;
}

.thumbnailContainer img:hover{
  width: 110%;
  top: -10%;
  left: -5%;
}

/* ===================
/* Footer */
/* =================== */ 

footer{
  text-align: center;
  padding: 32px 32px 0;
}

footer ul{
  display: flex;
  list-style: none;
  justify-content: center;
  gap: 16px;
  padding: 8px 0;

}

footer ul a{
  text-decoration: none;
  color: rgba(var(--fontColor),.7);
  font-weight: bold;
  transition: all .6s ease;
}

footer ul a:hover{
  color: rgba(var(--fontColor),1);
}

footer ul .active{
  color: var(--gradientColor2);
  pointer-events: none;
  
}

.footerSocial{
  display: flex;
  justify-content: center;
  gap: 16px;
}

.footSocial a{
  display: block;
}

.footerSocial a svg{
  aspect-ratio: 1;
  height: 32px;
  opacity: .7;
  fill: rgba(var(--fontColor),1);
  transition: opacity .6s ease, bottom .6s ease;
  position: relative;

}

.footerSocial a:hover svg{
  opacity: 1;
  bottom: 2px;
}


/* ===================
/* MEDIA QUERIES */
/* =================== */ 


@media screen and (max-width: 675px){

body{
  padding: 8px;
}

/* ==========
HEADER STUFF
========== */

header{
  display: block;
  padding-bottom: 32px;
  border-bottom: 3px solid var(--backgroundColor)
}

.imgAndLinks{
  max-width: 250px;
  margin: 0 auto;
}

h1{
  font-size: 2.1rem;
  line-height: 1.4em;
  padding-bottom:1em;
  padding-top: 1em;
}

#portrait{
  display: block;
  width: 200px;
  height: auto;
  margin: 0 auto;
}

.info{
  padding: 0;
  display: block;
  text-align: center;
}

.info p{
}

/* ==========
BODY STUFF
========== */

#meat{
  
}

#references{
  flex-direction: column;
  align-items: center;
}

figcaption img{
  width: 200px
}

#references section img{
  width: 200px;
}

#references section{
  margin-bottom: 32px;
}

/* ==========
Projects
========== */

.project{
  flex-direction: column-reverse;
}

.project .thumbnailContainer{
  width: 100%;
  border-left: none;
  border-bottom: 2px solid var(--gradientColor2)
}

.project .projectInfo{
  width: 100%;
  padding: 32px 16px;
}

.project .buttons{
  justify-content: center;
}

}

